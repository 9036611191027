import { merge } from "lodash"
import { PickupPointsData } from "~/layers/checkout/components/Step/Shipping/PickupPoints/Overview.props"
import type { Order } from "@commercelayer/sdk"

export type PickupPointStateProps = {
  isActive?: boolean
  data?: PickupPointsData["data"][0]
  searchInput?: string
  pickup?: {
    notSelectedOnCta?: number // Created to be a "Math.random()" number, so it gets re-executed everytime we click on the CTA
  }
  invoice?: {
    name?: string
    lastName?: string
    address?: string
    email?: string
    city?: string
    province?: string
    zipCode?: string
    inputConfirmed?: boolean
  }
  retire?: {
    fullName?: string
    phone?: string
    inputConfirmed?: boolean
  }
}

export type PickupPointMetadata = {
  pudo_id: string
  pudo_type: string
  pudo_name?: string
  pudo_address?: string
  pudo_city?: string
  pudo_state?: string
  pudo_country?: string
  pudo_postal_code?: string
  pudo_partner_id?: string
  pudo_courier?: string
}

export const getPickupPointStateData = () => {
  const pickupPointState = useState<PickupPointStateProps>("pickupPointData")
  return pickupPointState?.value || {}
}

export const setPickupPointStateData = (data: PickupPointStateProps) => {
  const pickupPointState = useState<PickupPointStateProps>("pickupPointData")
  const mergedState = merge(getPickupPointStateData(), data)
  pickupPointState.value = mergedState
  return getPickupPointStateData()
}

export const resetPickupPointStateData = () => {
  const pickupPointState = useState<PickupPointStateProps>("pickupPointData")
  pickupPointState.value = {
    data: undefined,
    isActive: false,
    pickup: undefined,
    retire: undefined,
    invoice: undefined,
    searchInput: ""
  }
}

export const pickupPointOrderMetadata = (data: PickupPointsData["data"][0]) => {
  if (data) {
    const metadata: PickupPointMetadata = {
      pudo_id: data.id,
      pudo_type: data.type,
      pudo_name: data.name,
      pudo_address: data.address,
      pudo_city: data.city,
      pudo_state: data.province,
      pudo_country: data.countryCode,
      pudo_postal_code: data.zipCode,
      pudo_partner_id: data.partnerId,
      pudo_courier: data.courier
    }
    return metadata
  } else return {}
}

export const getPickupPointRadius = () => {
  const defaultRadius = 10000
  const envRadius = useRuntimeConfig()?.public?.PICKUP_POINTS_RADIUS_METERS
  const radius = +(envRadius || defaultRadius)
  return radius // In meters
}

export const hydratePudoStateDataFromOrder = async (order: Ref<Order>) => {
  if (!order) return

  let stateData: Partial<PickupPointStateProps>

  let pickupData: Partial<PickupPointStateProps["data"]>
  let invoiceData: Partial<PickupPointStateProps["invoice"]>
  let retireData: Partial<PickupPointStateProps["retire"]>

  const shippingAddress = order.value.shipping_address
  const billingAddress = order.value.billing_address
  const shippingAddressPudoMetadata =
    shippingAddress?.metadata as PickupPointMetadata

  const id = shippingAddressPudoMetadata?.pudo_id
  if (!id) return

  stateData = { isActive: !!id }

  // PICKUP DATA
  if (shippingAddressPudoMetadata) {
    pickupData = {
      id,
      type: shippingAddressPudoMetadata?.pudo_type,
      name: shippingAddressPudoMetadata?.pudo_name,
      address: shippingAddressPudoMetadata?.pudo_address,
      city: shippingAddressPudoMetadata?.pudo_city,
      province: shippingAddressPudoMetadata?.pudo_state,
      countryCode: shippingAddressPudoMetadata?.pudo_country,
      zipCode: shippingAddressPudoMetadata?.pudo_postal_code,
      partnerId: shippingAddressPudoMetadata?.pudo_partner_id
    }

    const fetchedData = await $fetch(`/api/pickupPoint/getPickupPoint`, {
      method: "GET",
      params: { id }
    }).catch((e) => e)
    const compactTimeTable = fetchedData?.hits?.[0]?.compactTimeTable
    if (compactTimeTable) pickupData = { ...pickupData, compactTimeTable }
  }

  // INVOICE DATA
  if (billingAddress) {
    invoiceData = {
      address: billingAddress?.full_address!,
      city: billingAddress?.city,
      email: billingAddress?.email!,
      lastName: billingAddress?.last_name!,
      name: billingAddress?.first_name!,
      province: billingAddress?.state_code,
      zipCode: billingAddress?.zip_code!
    }
  }

  // RETIRE DATA
  if (shippingAddress) {
    retireData = {
      fullName: shippingAddress?.full_name!,
      phone: shippingAddress?.phone
    }
  }

  if (pickupData)
    stateData = {
      ...stateData,
      data: pickupData as PickupPointStateProps["data"]
    }
  if (invoiceData) stateData = { ...stateData, invoice: invoiceData }
  if (retireData) stateData = { ...stateData, retire: retireData }

  if (stateData) setPickupPointStateData(stateData)
}
