export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = await useNuxtApp()
  const { refreshCart, initCompare, cart, soldOutCompareData } = useCart()

  // not first load and  not if path remain the same but query changes
  if (to && to.path === from.path) return
  // not to cart page with soldOutCompareData
  if (to.name == "carrello" && soldOutCompareData.value?.length) return

  await refreshCart()
  await initCompare()

  // redirect navigation from checkout to cart if the order has no skus
  if (to.name === "checkout" && !cart.value?.skus_count) {
    return nuxtApp.runWithContext(() => navigateTo("/carrello"))
  }
})
