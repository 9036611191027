import type { RouterConfig } from "@nuxt/schema"
import qs from "qs"
import {
  isPageWithListing,
  isPaginationPageWithListing
} from "~/utils/paths/paths"

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
  scrollBehavior(to, _, savedPosition) {
    const nuxtApp = useNuxtApp()

    if (isPaginationPageWithListing(to.name as string)) {
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce("page:finish", () => {
          const waitForElement = setInterval(() => {
            const elementToScroll = document.querySelector(
              "#algolia-plp-filters"
            ) as any
            if (elementToScroll && elementToScroll.offsetTop) {
              clearInterval(waitForElement)

              window.scrollTo({
                top: elementToScroll.offsetTop - 50,
                behavior: "smooth"
              })
              resolve({ left: 0, top: elementToScroll.offsetTop })
            }
          }, 100)
        })
      })
    }

    if (
      isPageWithListing(to.name as string) &&
      !!Object.values(to.query).length
    ) {
      return new Promise((resolve) => {
        const scrollToElement = () => {
          const elementToScroll = document.querySelector(
            "#algolia-plp-filters"
          ) as HTMLElement
          if (elementToScroll && elementToScroll.offsetTop) {
            window.scrollTo({
              top: elementToScroll.offsetTop - 50,
              behavior: "smooth"
            })
            resolve({ left: 0, top: elementToScroll.offsetTop })
            return true
          }
          return false
        }

        // Fallback if elementToScroll in not ready
        if (!scrollToElement()) {
          const waitForElement = setInterval(() => {
            if (scrollToElement()) {
              clearInterval(waitForElement)
            }
          }, 100)

          nuxtApp.hooks.hookOnce("page:finish", () => {
            if (scrollToElement()) {
              clearInterval(waitForElement)
            }
          })
        }
      })
    }

    // If history back
    if (savedPosition) {
      // Handle Suspense resolution
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce("page:finish", () => {
          setTimeout(() => resolve(savedPosition), 50)
        })
      })
    }
    // Scroll to heading on click

    if (to.hash) {
      setTimeout(() => {
        const heading = document.querySelector(to.hash) as any
        return (
          heading &&
          window.scrollTo({
            top: heading.offsetTop,
            behavior: "smooth"
          })
        )
      }, 500)
      return
    }

    // Scroll to top of window
    nuxtApp.hooks.hookOnce("page:finish", () => {
      return window.scrollTo({
        top: 0
      })
    })
  }
}
