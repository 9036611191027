import aa from "search-insights"
import { useUserStore } from "~/stores/user"
import Cookie from "js-cookie"
import { storeToRefs } from "pinia"
import {
  AlgoliaAddToCartObjectData,
  algoliaEventsNames,
  AlgoliaProductSharedItem,
  AlgoliaPurchasedObjectData
} from "~/types/algolia-events"
import { getAlgoliaAddToCartInsightsPayload } from "~/utils/algolia/algolia-events-payload"

export type RecommendedRuleContexts = "only_in_stock"

export enum EAnalyticsTag {
  autocomplete = "autocomplete",
  srp = "SRP"
}

export const getUserId = () => {
  const userStore = useUserStore()
  const { userId } = storeToRefs(userStore)
  return Cookie.get("_ALGOLIA") ?? userId.value
}

export const initAlgolia = () => {
  const user = getUserId()
  aa("setUserToken", user)
}

export const algoliaEventClickProductAfterSearch = (
  index: any,
  eventName: string,
  objectIDs: string[],
  queryID: string,
  positions: number[]
) => {
  const user = getUserId()
  aa("clickedObjectIDsAfterSearch", {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs,
    queryID,
    positions: positions ?? []
  })
}

export const algoliaEventClickProduct = (
  index: any,
  eventName: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa("clickedObjectIDs", {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs
  })
}

export const algoliaEventAddToCartAfterSearch = (
  index: any,
  eventName: string,
  queryID: string,
  objectIDs: string[],
  objectData?: AlgoliaAddToCartObjectData[],
  value?: number,
  currency?: string
) => {
  const user = getUserId()
  aa(algoliaEventsNames.addedToCartObjectIDsAfterSearch, {
    userToken: user,
    index: index.indexName,
    eventName,
    queryID,
    objectIDs,
    objectData,
    value,
    currency
  })
}

export const algoliaEventAddToCart = (
  index: any,
  eventName: string,
  objectIDs: string[],
  objectData?: AlgoliaAddToCartObjectData[],
  value?: number,
  currency?: string
) => {
  const user = getUserId()
  aa(algoliaEventsNames.addedToCartObjectIDs, {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs,
    objectData,
    value,
    currency
  })
}

export const viewProductDetail = (
  index: any,
  eventName: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa("viewedObjectIDs", {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs
  })
}

export const hitsViewed = (
  insightsMethod: any,
  index: string,
  eventName: string,
  objectIDs: string[]
) => {
  const user = getUserId()
  aa(insightsMethod, {
    userToken: user,
    index,
    eventName,
    objectIDs
  })
}

export const excludeOutOfMarketRuleContext = "exclude_out_of_market"
export const excludeOutOfStockRuleContext = "exclude_out_of_stock"

export const buildExcludeFilter = (params?: {
  excludeOutOfMarket?: boolean
  excludeOutOfStock?: boolean
}) => {
  return {
    ruleContexts: [
      ...(!!params?.excludeOutOfMarket ? [excludeOutOfMarketRuleContext] : []),
      ...(!!params?.excludeOutOfStock ? [excludeOutOfStockRuleContext] : [])
    ]
  }
}

// RULE CONTEXT FOR ALGOLIA RECOMMENDED
export const getRecommendationRuleContexts = (
  ruleContexts?: RecommendedRuleContexts[]
) => {
  return {
    ...(ruleContexts?.length
      ? { queryParameters: { ruleContexts: [...ruleContexts] } }
      : {})
  }
}

export function sendAlgoliaAddToCartEvent(
  productItemForInsights: AlgoliaProductSharedItem,
  algoliaIndex: any,
  queryID?: string
) {
  const { objectData, eventName, objectIDs, value, currency } =
    getAlgoliaAddToCartInsightsPayload(productItemForInsights)

  if (queryID) {
    algoliaEventAddToCartAfterSearch(
      algoliaIndex,
      eventName,
      queryID,
      objectIDs,
      objectData,
      value,
      currency
    )
  } else {
    algoliaEventAddToCart(
      algoliaIndex,
      eventName,
      objectIDs,
      objectData,
      value,
      currency
    )
  }
}

export const algoliaEventPurchasedAfterSearch = (
  index: any,
  eventName: string,
  objectIDs: string[],
  objectData?: AlgoliaPurchasedObjectData[],
  value?: number,
  currency?: string
) => {
  const user = getUserId()
  aa(algoliaEventsNames.purchasedObjectIDsAfterSearch, {
    userToken: user,
    index: index.indexName,
    eventName,
    objectIDs,
    objectData,
    value,
    currency
  })
}

export function sendAlgoliaPurchasedEvent() {}
