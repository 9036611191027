import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/app/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pinceau_nuxt_plugin_client_bfcCy9a5iq from "/app/.nuxt/pinceau-nuxt-plugin.client.mjs";
import i18n_Fspu1Dj02B from "/app/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import plugin_8SbxDRbG6Y from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_BqLkNUef68 from "/app/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import routesMap_nAnXaUMsXb from "/app/.nuxt/gluon/routesMap.ts";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gtm_page_view_event_client_PrMYmmbmaC from "/app/plugins/gtm-page-view-event.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import commercelayer_foCKWy86k8 from "/app/plugins/commercelayer.ts";
import vue_gtm_client_stBjLl0OeM from "/app/plugins/vue-gtm.client.ts";
import algolia_insights_client_TZbfLB2zaq from "/app/plugins/algolia-insights.client.ts";
import loggingHandler_x5m2ashPqU from "/app/plugins/loggingHandler.ts";
import maska_UHaKf2z1iQ from "/app/plugins/maska.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pinceau_nuxt_plugin_client_bfcCy9a5iq,
  i18n_Fspu1Dj02B,
  plugin_8SbxDRbG6Y,
  i18n_yfWm7jX06p,
  plugin_BqLkNUef68,
  routesMap_nAnXaUMsXb,
  chunk_reload_client_UciE0i6zes,
  gtm_page_view_event_client_PrMYmmbmaC,
  sentry_client_shVUlIjFLk,
  commercelayer_foCKWy86k8,
  vue_gtm_client_stBjLl0OeM,
  algolia_insights_client_TZbfLB2zaq,
  loggingHandler_x5m2ashPqU,
  maska_UHaKf2z1iQ
]