import { Sku } from "@commercelayer/sdk"
import { ObjectWithObjectID } from "@algolia/client-search"

/**
 * @param {string} unitType - The unit type to check
 * @returns {boolean} Returns true if the unit type is ok
 */
// TODO: Use an enum to check if the unit type is something in our cms
const checkUnitType = (unitType = ""): boolean => {
  return Boolean(unitType)
}

/**
 * @param {number} pricePerUnit - the price per unit
 * @param {string} unitType - unit type to check
 * @returns {boolean} Returns true if the Price per unit is displayable
 */
const isDisplayablePricePerUnit = (
  pricePerUnit?: number | string,
  unitType?: string
): boolean => {
  const value = Number(pricePerUnit)

  return !isNaN(value) && value > 0 && checkUnitType(unitType)
}

const calculateMultiStockLocationAvailability = (product: Sku | undefined) => {
  return calculateMultiStockLocationStock(product) > 0
}

const calculateMultiStockLocationStock = (product: Sku | undefined) => {
  return Math.max(
    0,
    product?.stock_items?.reduce(
      (cumulated_stock, stock_item) =>
        cumulated_stock +
        (stock_item.quantity ?? 0) -
        (stock_item.reserved_stock?.quantity ?? 0),
      0
    ) ?? 0
  )
}

const isSellableStatus = (sellabilityStatus: string | undefined) => {
  return (
    !sellabilityStatus ||
    !["not_sellable", "out_of_market"].includes(sellabilityStatus)
  )
}

const isIndexableStatus = (sellabilityStatus: string | undefined) => {
  return !sellabilityStatus || !["out_of_market"].includes(sellabilityStatus)
}

const getSellableProductOrNull = (product: ProductAlgolia) => {
  return isIndexableStatus(product?.sellabilityStatus) ? product : null
}

const getItemsInStockOrRelatedItemsFromAlgolia = async (
  products: (ProductAlgolia | ObjectWithObjectID | null)[]
) => {
  const algoliaProductsInStock = await Promise.all(
    products.map(async (product) => {
      if (product?.inStock as boolean) {
        return product
      }

      const category = product?.mainCategory?.slug

      let categoryLvl = "mainCategory"
      for (const [lvl, cat] of Object.entries(product?.categories)) {
        if (cat.some((element) => element.slug == category)) {
          categoryLvl = `categories.${lvl}.slug`
          break
        }
      }

      const recommendResult = await $fetch(
        `/api/algolia/getRecommendations/recommend-9-single/${product?.productCode}/${categoryLvl}/${category}`
      )

      const similarProducts =
        _flattenDeep(recommendResult?.results?.map((item) => item.hits)) ?? []

      const mappedSimilarProducts = similarProducts

      return mappedSimilarProducts[0] || getSellableProductOrNull(product)
    })
  )
  return algoliaProductsInStock
}

export const productUtils = {
  checkUnitType,
  calculateMultiStockLocationStock,
  calculateMultiStockLocationAvailability,
  isDisplayablePricePerUnit,
  isSellableStatus,
  isIndexableStatus,
  getItemsInStockOrRelatedItemsFromAlgolia
}
