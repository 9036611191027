export const loginSignupPath = "/accedi-registrati"
export const personalAreaPath = "/area-personale"

const paginationPageWithListingCheckList = [
  "ricerca-page-page",
  "c-category-page-page",
  "b-brand-page-page",
  "generic-page-page",
  "piu-acquistati-page-page",
  "enciclopedia-category-page-page",
  "promozioni-brand-slug-page-page",
  "promozioni-interne-slug-page-page",
  "top-magazine-category-page-page",
  "top-magazine-tutti-i-post-page-page"
]

const pageWithListingCheckList = [
  "ricerca",
  "c-category",
  "b-brand",
  "generic",
  "piu-acquistati",
  "enciclopedia-category",
  "promozioni-brand-slug",
  "promozioni-interne-slug",
  "top-magazine-category",
  "top-magazine-tutti-i-post"
]

export const appPaths = {
  home: "/",
  loginSignup: `${loginSignupPath}`,
  login: `${loginSignupPath}/login`,
  signup: `${loginSignupPath}/crea-account`,
  passwordForget: `/password-dimenticata`,
  socialLogin: "/social-login",
  savedForLater: `${personalAreaPath}/preferiti`
}

export function isPaginationPageWithListing(path: string) {
  return paginationPageWithListingCheckList.includes(path)
}
export function isPageWithListing(path: string) {
  return pageWithListingCheckList.includes(path)
}
