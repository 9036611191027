<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"

const pathsToServeSimpleErrors = [
  "/_nuxt/",
  "/fonts/",
  "/icons/",
  "/favicon.ico",
  "/api/",
  "/img/",
  "/apple-"
]
const headers = useRequestHeaders(["accept"])
const fullPath = useRoute().fullPath
const isToServeSimpleError =
  process.server &&
  (!headers?.accept?.includes("text/html") ||
    pathsToServeSimpleErrors.some((path) => fullPath.startsWith(path)))
const nuxtApp = await useNuxtApp()
const error = await nuxtApp.runWithContext(() => useError())
const isPageNotFound = computed(() =>
  error.value ? error.value?.statusCode === 404 : null
)
const userAgent = process.client
  ? navigator?.userAgent
  : await nuxtApp.runWithContext(() => useRequestHeaders()?.["user-agent"])
const isBot =
  userAgent &&
  ["googlebot", "inspectiontool", "storebot"].some((userAgentToken) =>
    userAgent.toLowerCase().includes(userAgentToken)
  )
let errorData: globalThis.Ref<any> | undefined

if (isToServeSimpleError) {
  nuxtApp.ssrContext?.event.node.res.writeHead(error.value?.statusCode || 500, {
    "Content-Type": "text/plain"
  })
  nuxtApp.ssrContext?.event.node.res.end(
    `${error.value?.statusCode} ${error.value?.message}`
  )
} else {
  const { initCart } = await nuxtApp.runWithContext(() => useCart())
  const userStore = await nuxtApp.runWithContext(() => useUserStore())
  const { userId, sessionChecked } = storeToRefs(userStore)
  const isLogged = await nuxtApp.runWithContext(() => useIsLogged())

  const alreadyVisited: any = await nuxtApp.runWithContext(() =>
    useStatefulCookie("already-visited", {
      default: () => "",
      maxAge: 5184000
    })
  )

  const mounted = ref(false)
  onMounted(() => {
    mounted.value = true
    alreadyVisited.value =
      // check if cookie has empty string value
      alreadyVisited.value === ""
        ? // if so assign variable with false value (user did not visit the website yet)
          false
        : // otherwise check if cookie has false value
        alreadyVisited.value === false
        ? // if so assign variable with true value
          true
        : // otherwise return variable value
          alreadyVisited.value
  })

  watch(
    [sessionChecked, mounted, userId],
    ([sessionChecked, mounted, userId]) => {
      if (!sessionChecked || !mounted || (isLogged.value && !userId)) {
        return
      }

      initCart()
    },
    {
      immediate: true
    }
  )

  // const isPageNotLoaded = computed(() => error?.statusCode === 500)

  const errorMessage = computed(() => {
    const statusCode = error.value?.statusCode ?? "500"
    const statusMessage = error.value?.statusMessage ?? "Internal Server Error"
    return statusCode !== "" ? `${statusCode} ${statusMessage}` : statusMessage
  })

  onMounted(async () => {
    const { event, sendEvent } = await nuxtApp.runWithContext(() =>
      useGAEvent(
        `event_error_feedback_${error.value?.statusCode.toString() ?? "500"}`,
        "custom_event"
      )
    )
    event.value = {
      event: "error_feedback",
      category: "error",
      action: `${error.value?.statusCode.toString() ?? "500"}`,
      feedback_title: errorMessage.value ?? ""
    }

    sendEvent({ clearEcommerce: false })
  })

  // https://developers.google.com/search/docs/crawling-indexing/overview-google-crawlers#common-crawlers

  const { data } = await nuxtApp.runWithContext(() =>
    useFetch("/api/getErrorPageContent")
  )
  errorData = data

  if (process.server && error.value?.message == "[nuxt] instance unavailable") {
    const path = await nuxtApp.runWithContext(() => useRoute().fullPath)
    useHead({
      meta: [
        {
          "http-equiv": "refresh",
          content: `0, url=${path}`
        }
      ]
    })
  }
}
</script>

<template>
  <div class="overflow-hidden" v-if="!isToServeSimpleError">
    <NuxtLayout name="default">
      <div
        class="
          flex flex-col
          items-center
          justify-center
          py-4
          text-center
          md:py-6
        "
      >
        <UtilsIcon name="Browser1.svg" class="mb-5" />
        <template v-if="isPageNotFound">
          <h1 class="elephant-bold md:mammoth-bold mb-3">
            {{ $t("errorPages.error404.title") }}
          </h1>
          <p class="pig text-black-80">
            {{ $t("errorPages.error404.text") }}
          </p>
        </template>
        <p v-else-if="isBot">
          Farmacia Online, integratori, prodotti per la salute, cura del viso,
          corpo e pelle - Top Farmacia
        </p>
        <pre v-else>{{ error?.message ?? "error" }}</pre>
      </div>

      <DynamicContent v-if="errorData" :components="errorData" />
    </NuxtLayout>
  </div>
</template>
