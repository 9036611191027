<script lang="ts" setup>
import {
  _SkuListItemCreate,
  SaveForLaterProductForAction
} from "~/composables/useSavedForLater"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

const props = withDefaults(
  defineProps<{
    product: SaveForLaterProductForAction
    isCard: boolean
    iconColor?: string
    savedIcon?: string
    notSavedIcon?: string
  }>(),
  {
    isCard: false
  }
)
const {
  lineItems: savedForLaterLineItems,
  savedForLaterLoading,
  handleRemoveSavedForLaterBySku,
  handleSaveForLaterBySkuListOrSkuId,
  setAnonymousSaveForLaterProductStorage
} = useSavedForLater()
const isLogged = useIsLogged()

const isSavedForLater = computed(() => {
  return savedForLaterLineItems.value.some(
    (savedForLaterItem) =>
      savedForLaterItem.sku_code === props.product.productId
  )
})

const isOutOfStock = computed(() => {
  return !props.product.metadata.inStock
})

const buttonTheme = computed(() => {
  if (isSavedForLater.value) {
    return `stretched-green`
  } else {
    return `shadowed`
  }
})

const emit =
  defineEmits<{
    (e: "onSaveForLater"): void
  }>()

const handleSavedForLaterAction = async (
  product: SaveForLaterProductForAction
) => {
  if (!isLogged.value || !savedForLaterLoading) {
    setAnonymousSaveForLaterProductStorage(product)
    navigateToLoginSignupPage()
    return
  }

  if (isSavedForLater.value) {
    handleRemoveSavedForLaterBySku(product)
    return
  }
  emit("onSaveForLater")
  handleSaveForLaterBySkuListOrSkuId(product)
}

const savedIconName = computed(() => props?.savedIcon || "Heart.svg")
const notSavedIconName = computed(() => props?.notSavedIcon || "EmptyHeart.svg")
</script>

<template>
  <UtilsButton
    :theme="buttonTheme"
    :class="[
      { 'ml-2 h-11 w-11': isCard },
      { 'w-10 md:w-20 lg:w-20 xl:w-12': !isCard && !isOutOfStock },
      { 'w-12': !isCard && isOutOfStock }
    ]"
    :container-class="isSavedForLater ? '' : 'btn-contained-white'"
    :isInnerFullHeight="true"
    @click="handleSavedForLaterAction(product)"
  >
    <template #icon>
      <UtilsIcon
        :name="isSavedForLater ? savedIconName : notSavedIconName"
        class="h-4 w-4 md:h-6 md:w-6"
        :color="iconColor || (isSavedForLater ? 'white' : '')"
      />
    </template>
  </UtilsButton>
</template>

<style></style>
