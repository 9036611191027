import { currencies } from "~/utils/constants"

export interface AlgoliaBaseEvent {
  index: any,
  userToken: string,
  eventName: string,
  objectIDs: string[],
}

export interface AlgoliaAddToCartObjectData {
  price?: number | string
  discount?: number | string
  quantity?: number
}

export interface AlgoliaAddToCartEvent extends AlgoliaBaseEvent {
  objectData?: AlgoliaAddToCartObjectData[],
  value?: number | string,
  currency?: string
}

export interface AlgoliaProductSharedItem {
  price: number,
  currency: currencies.eur,
  oldPrice?: number,
  productCode?: string | null,
  quantity?: number,
}

export interface AlgoliaPurchasedObjectData extends AlgoliaAddToCartObjectData{
  queryID?: string
}

export enum algoliaEventsNames {
  addedToCartObjectIDsAfterSearch = "addedToCartObjectIDsAfterSearch",
  addedToCartObjectIDs = "addedToCartObjectIDs",
  purchasedObjectIDsAfterSearch = "purchasedObjectIDsAfterSearch",
}
