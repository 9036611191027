import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45gtm_45global from "/app/middleware/00.gtm.global.ts";
import check_45sould_45out_45global from "/app/middleware/checkSouldOut.global.ts";
import restore_45header_45global from "/app/middleware/restoreHeader.global.ts";
import save_45return_45url_45global from "/app/middleware/save-return-url.global.ts";
import track_45referrer_45global from "/app/middleware/trackReferrer.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45gtm_45global,
  check_45sould_45out_45global,
  restore_45header_45global,
  save_45return_45url_45global,
  track_45referrer_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  "logged-in-redirect-home": () => import("/app/middleware/logged-in-redirect-home.ts"),
  precheckout: () => import("/app/middleware/precheckout.ts"),
  "user-check-redirect-login-signup-page": () => import("/app/middleware/user-check-redirect-login-signup-page.ts")
}