import { LineItem } from "@commercelayer/sdk"
import {
  ORDER_COMPLETED,
  PRODUCT_ADDED_TO_CART
} from "~/config/algolia-insights"
import {
  AlgoliaProductSharedItem,
  AlgoliaAddToCartObjectData,
  AlgoliaPurchasedObjectData
} from "~/types/algolia-events"
import { currencies } from "~/utils/constants"
import { roundToTwoDecimals } from "~/utils/formatters"

export function getAlgoliaAddToCartForInsights(
  price: number,
  productCode: string,
  quantity: number,
  oldPrice?: number,
  currency: currencies.eur = currencies.eur
): AlgoliaProductSharedItem {
  return {
    currency,
    oldPrice,
    price,
    productCode,
    quantity
  }
}

export function getAlgoliaObjectData<T>(
  price: number,
  quantity?: number,
  discount?: number,
  queryID?: string
): T {
  return {
    price,
    quantity,
    discount,
    ...(queryID && { queryID })
  } as T
}

export function getAlgoliaProductSharedPayload(
  item: AlgoliaProductSharedItem,
  eventName: string
) {
  const value = item.quantity
    ? roundToTwoDecimals(item.price * item.quantity)
    : undefined
  const objectIDs = [`product_${item.productCode}`]
  const currency = item.currency || currencies.eur

  return {
    eventName,
    objectIDs,
    value,
    currency
  }
}

export function getAlgoliaAddToCartInsightsPayload(
  item: AlgoliaProductSharedItem
) {
  const sharedPayload = getAlgoliaProductSharedPayload(
    item,
    PRODUCT_ADDED_TO_CART
  )
  const price = item.price
  const discount = item.oldPrice
    ? roundToTwoDecimals(item.oldPrice - item.price)
    : undefined
  const objectData: AlgoliaAddToCartObjectData[] = [
    getAlgoliaObjectData<AlgoliaAddToCartObjectData>(
      price,
      item.quantity,
      discount
    )
  ]

  return {
    ...sharedPayload,
    objectData
  }
}

export function getAlgoliaPurchasedAfterSearchObjectData(
  items: LineItem[]
): AlgoliaPurchasedObjectData[] {
  return items.map((item) => {
    const oldPrice = item.compare_at_amount_float as number
    const price = item.unit_amount_float as number
    const discount = oldPrice ? roundToTwoDecimals(oldPrice - price) : undefined

    return getAlgoliaObjectData<AlgoliaPurchasedObjectData>(
      item?.unit_amount_float as number,
      item.quantity,
      discount,
      item.metadata?.afterSearch?.queryID
    )
  })
}

export function getAlgoliaPurchasedAfterSearchInsightsPayload(
  items: LineItem[]
) {
  const withoutHelperProducts = items.filter((item) => !!item.sku_code)
  const objectData: AlgoliaPurchasedObjectData[] =
    getAlgoliaPurchasedAfterSearchObjectData(withoutHelperProducts)
  const value: number = roundToTwoDecimals(
    objectData.reduce((acc, { price, quantity }) => {
      return acc + (price as number) * (quantity as number)
    }, 0)
  )

  return {
    eventName: ORDER_COMPLETED,
    objectIDs: withoutHelperProducts.map((item) => `product_${item.sku_code}`),
    objectData,
    value,
    currency: currencies.eur
  }
}
