import { default as index00WfjSXmQaMeta } from "/app/pages/[generic]/index.vue?macro=true";
import { default as _91page_93gUgoEAiH2DMeta } from "/app/pages/[generic]/page/[page].vue?macro=true";
import { default as index4ae5MpLGcDMeta } from "/app/pages/accedi-registrati/crea-account/index.vue?macro=true";
import { default as indexJCpN4CX7xjMeta } from "/app/pages/accedi-registrati/index.vue?macro=true";
import { default as indexjthaA09CQZMeta } from "/app/pages/accedi-registrati/login/index.vue?macro=true";
import { default as index2DywVPwARaMeta } from "/app/pages/area-personale/acquisti-resi/index.vue?macro=true";
import { default as _91orderID_938ssmZsbFmyMeta } from "/app/pages/area-personale/acquisti-resi/ordine/[orderID].vue?macro=true";
import { default as _91orderID_931E0MxVkjhhMeta } from "/app/pages/area-personale/acquisti-resi/reso/[orderID].vue?macro=true";
import { default as buoniASECSXzfXaMeta } from "/app/pages/area-personale/buoni.vue?macro=true";
import { default as datizL3d1aRDOWMeta } from "/app/pages/area-personale/dati.vue?macro=true";
import { default as indexoSrfXOEgdwMeta } from "/app/pages/area-personale/index.vue?macro=true";
import { default as _91editID_93Sqd2Rm2b2lMeta } from "/app/pages/area-personale/indirizzi/[editID].vue?macro=true";
import { default as indexOlDRqa8GzFMeta } from "/app/pages/area-personale/indirizzi/index.vue?macro=true";
import { default as nuovoIKw2vQ80gdMeta } from "/app/pages/area-personale/indirizzi/nuovo.vue?macro=true";
import { default as index4mi8Oh4KGnMeta } from "/app/pages/area-personale/preferiti/index.vue?macro=true";
import { default as _91page_93tpn8wGPIwuMeta } from "/app/pages/area-personale/preferiti/page/[page].vue?macro=true";
import { default as prodotti_45disponibiliVMGKBK6bLMMeta } from "/app/pages/area-personale/prodotti-disponibili.vue?macro=true";
import { default as wishlist5f1t4McwdNMeta } from "/app/pages/area-personale/wishlist.vue?macro=true";
import { default as indexzbqkdctIA2Meta } from "/app/pages/b-[brand]/[slug]/index.vue?macro=true";
import { default as _91page_93bmo7HXTGS0Meta } from "/app/pages/b-[brand]/[slug]/page/[page].vue?macro=true";
import { default as indexgj55yT9HTjMeta } from "/app/pages/b-[brand]/index.vue?macro=true";
import { default as _91page_9323RygVuFlEMeta } from "/app/pages/b-[brand]/page/[page].vue?macro=true";
import { default as brands3yQXfZudBaMeta } from "/app/pages/brands.vue?macro=true";
import { default as indexae5jzt8yUaMeta } from "/app/pages/c-[category]/index.vue?macro=true";
import { default as _91page_937nLnngNFZ9Meta } from "/app/pages/c-[category]/page/[page].vue?macro=true";
import { default as carrellon5jYLIrO6hMeta } from "/app/pages/carrello.vue?macro=true";
import { default as _91id_93qGb7sUHr7XMeta } from "/app/pages/checkout/[id].vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as chi_45siamo4wnUAYuAy7Meta } from "/app/pages/chi-siamo.vue?macro=true";
import { default as contattix7y2v3AkrRMeta } from "/app/pages/contatti.vue?macro=true";
import { default as domande_45frequentiZF8EzGCEOLMeta } from "/app/pages/domande-frequenti.vue?macro=true";
import { default as _91article_935h3hfjk74RMeta } from "/app/pages/enciclopedia/[category]/[article].vue?macro=true";
import { default as indexgBJu1xTQdjMeta } from "/app/pages/enciclopedia/[category]/index.vue?macro=true";
import { default as _91page_93cTc0wwZ6lcMeta } from "/app/pages/enciclopedia/[category]/page/[page].vue?macro=true";
import { default as indexQN5gLOZUALMeta } from "/app/pages/enciclopedia/index.vue?macro=true";
import { default as health_45checkePC2yKKeYyMeta } from "/app/pages/health-check.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91refOrder_93t3RyyYtg6rMeta } from "/app/pages/ordine/traccia/[email]/[refOrder].vue?macro=true";
import { default as trovaz5vtktBJj7Meta } from "/app/pages/ordine/trova.vue?macro=true";
import { default as p_45_91product_93_46consteMNeruBsHaMeta } from "/app/pages/p-[product].const.ts?macro=true";
import { default as p_45_91product_93JZZXHGzIDiMeta } from "/app/pages/p-[product].vue?macro=true";
import { default as index3xmEShFSahMeta } from "/app/pages/password-dimenticata/index.vue?macro=true";
import { default as modifica_45passwordUajkWhab4AMeta } from "/app/pages/password-dimenticata/modifica-password.vue?macro=true";
import { default as profilo_45cancellatoLQf3d6xdH5Meta } from "/app/pages/profilo-cancellato.vue?macro=true";
import { default as indexBdCcNvrCQpMeta } from "/app/pages/promozioni/brand/[slug]/index.vue?macro=true";
import { default as _91page_93kxOMRHX44TMeta } from "/app/pages/promozioni/brand/[slug]/page/[page].vue?macro=true";
import { default as indexV25O3JvHGaMeta } from "/app/pages/promozioni/index.vue?macro=true";
import { default as indexon2XdcPEqSMeta } from "/app/pages/promozioni/interne/[slug]/index.vue?macro=true";
import { default as _91page_93LCyVMxJvCIMeta } from "/app/pages/promozioni/interne/[slug]/page/[page].vue?macro=true";
import { default as _91slug_93UxcvBpzPKEMeta } from "/app/pages/promozioni/special/[slug].vue?macro=true";
import { default as indexHdSe9JRZUEMeta } from "/app/pages/ricerca/index.vue?macro=true";
import { default as _91page_93K8pSmpWFr0Meta } from "/app/pages/ricerca/page/[page].vue?macro=true";
import { default as _91file_93RPNxnkYSxJMeta } from "/app/pages/ricevuta/[file].vue?macro=true";
import { default as richiedi_45prodottoUBMumdHv2DMeta } from "/app/pages/richiedi-prodotto.vue?macro=true";
import { default as scontiWdVPkm1ymBMeta } from "/app/pages/sconti.vue?macro=true";
import { default as sitemapbFBPe0edUzMeta } from "/app/pages/sitemap.vue?macro=true";
import { default as social_45loginFeA9uOUMWOMeta } from "/app/pages/social-login.vue?macro=true";
import { default as indexha0kWLU1LPMeta } from "/app/pages/top-magazine/[category]/[slug]/index.vue?macro=true";
import { default as indexKNeVqgUZ73Meta } from "/app/pages/top-magazine/[category]/index.vue?macro=true";
import { default as _91page_93HJVRPFnC4TMeta } from "/app/pages/top-magazine/[category]/page/[page].vue?macro=true";
import { default as indexN3DvlIEeETMeta } from "/app/pages/top-magazine/index.vue?macro=true";
import { default as indexr1eLSmTyQpMeta } from "/app/pages/top-magazine/tutti-i-post/index.vue?macro=true";
import { default as _91page_93LgSLLPnjLwMeta } from "/app/pages/top-magazine/tutti-i-post/page/[page].vue?macro=true";
import { default as component_45stubTOq3NWgWLHMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: index00WfjSXmQaMeta?.name ?? "generic",
    path: index00WfjSXmQaMeta?.path ?? "/:generic()",
    meta: index00WfjSXmQaMeta || {},
    alias: index00WfjSXmQaMeta?.alias || [],
    redirect: index00WfjSXmQaMeta?.redirect,
    component: () => import("/app/pages/[generic]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93gUgoEAiH2DMeta?.name ?? "generic-page-page",
    path: _91page_93gUgoEAiH2DMeta?.path ?? "/:generic()/page/:page()",
    meta: _91page_93gUgoEAiH2DMeta || {},
    alias: _91page_93gUgoEAiH2DMeta?.alias || [],
    redirect: _91page_93gUgoEAiH2DMeta?.redirect,
    component: () => import("/app/pages/[generic]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: index4ae5MpLGcDMeta?.name ?? "accedi-registrati-crea-account",
    path: index4ae5MpLGcDMeta?.path ?? "/accedi-registrati/crea-account",
    meta: index4ae5MpLGcDMeta || {},
    alias: index4ae5MpLGcDMeta?.alias || [],
    redirect: index4ae5MpLGcDMeta?.redirect,
    component: () => import("/app/pages/accedi-registrati/crea-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCpN4CX7xjMeta?.name ?? "accedi-registrati",
    path: indexJCpN4CX7xjMeta?.path ?? "/accedi-registrati",
    meta: indexJCpN4CX7xjMeta || {},
    alias: indexJCpN4CX7xjMeta?.alias || [],
    redirect: indexJCpN4CX7xjMeta?.redirect,
    component: () => import("/app/pages/accedi-registrati/index.vue").then(m => m.default || m)
  },
  {
    name: indexjthaA09CQZMeta?.name ?? "accedi-registrati-login",
    path: indexjthaA09CQZMeta?.path ?? "/accedi-registrati/login",
    meta: indexjthaA09CQZMeta || {},
    alias: indexjthaA09CQZMeta?.alias || [],
    redirect: indexjthaA09CQZMeta?.redirect,
    component: () => import("/app/pages/accedi-registrati/login/index.vue").then(m => m.default || m)
  },
  {
    name: index2DywVPwARaMeta?.name ?? "area-personale-acquisti-resi",
    path: index2DywVPwARaMeta?.path ?? "/area-personale/acquisti-resi",
    meta: index2DywVPwARaMeta || {},
    alias: index2DywVPwARaMeta?.alias || [],
    redirect: index2DywVPwARaMeta?.redirect,
    component: () => import("/app/pages/area-personale/acquisti-resi/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderID_938ssmZsbFmyMeta?.name ?? "area-personale-acquisti-resi-ordine-orderID",
    path: _91orderID_938ssmZsbFmyMeta?.path ?? "/area-personale/acquisti-resi/ordine/:orderID()",
    meta: _91orderID_938ssmZsbFmyMeta || {},
    alias: _91orderID_938ssmZsbFmyMeta?.alias || [],
    redirect: _91orderID_938ssmZsbFmyMeta?.redirect,
    component: () => import("/app/pages/area-personale/acquisti-resi/ordine/[orderID].vue").then(m => m.default || m)
  },
  {
    name: _91orderID_931E0MxVkjhhMeta?.name ?? "area-personale-acquisti-resi-reso-orderID",
    path: _91orderID_931E0MxVkjhhMeta?.path ?? "/area-personale/acquisti-resi/reso/:orderID()",
    meta: _91orderID_931E0MxVkjhhMeta || {},
    alias: _91orderID_931E0MxVkjhhMeta?.alias || [],
    redirect: _91orderID_931E0MxVkjhhMeta?.redirect,
    component: () => import("/app/pages/area-personale/acquisti-resi/reso/[orderID].vue").then(m => m.default || m)
  },
  {
    name: buoniASECSXzfXaMeta?.name ?? "area-personale-buoni",
    path: buoniASECSXzfXaMeta?.path ?? "/area-personale/buoni",
    meta: buoniASECSXzfXaMeta || {},
    alias: buoniASECSXzfXaMeta?.alias || [],
    redirect: buoniASECSXzfXaMeta?.redirect,
    component: () => import("/app/pages/area-personale/buoni.vue").then(m => m.default || m)
  },
  {
    name: datizL3d1aRDOWMeta?.name ?? "area-personale-dati",
    path: datizL3d1aRDOWMeta?.path ?? "/area-personale/dati",
    meta: datizL3d1aRDOWMeta || {},
    alias: datizL3d1aRDOWMeta?.alias || [],
    redirect: datizL3d1aRDOWMeta?.redirect,
    component: () => import("/app/pages/area-personale/dati.vue").then(m => m.default || m)
  },
  {
    name: indexoSrfXOEgdwMeta?.name ?? "area-personale",
    path: indexoSrfXOEgdwMeta?.path ?? "/area-personale",
    meta: indexoSrfXOEgdwMeta || {},
    alias: indexoSrfXOEgdwMeta?.alias || [],
    redirect: indexoSrfXOEgdwMeta?.redirect,
    component: () => import("/app/pages/area-personale/index.vue").then(m => m.default || m)
  },
  {
    name: _91editID_93Sqd2Rm2b2lMeta?.name ?? "area-personale-indirizzi-editID",
    path: _91editID_93Sqd2Rm2b2lMeta?.path ?? "/area-personale/indirizzi/:editID()",
    meta: _91editID_93Sqd2Rm2b2lMeta || {},
    alias: _91editID_93Sqd2Rm2b2lMeta?.alias || [],
    redirect: _91editID_93Sqd2Rm2b2lMeta?.redirect,
    component: () => import("/app/pages/area-personale/indirizzi/[editID].vue").then(m => m.default || m)
  },
  {
    name: indexOlDRqa8GzFMeta?.name ?? "area-personale-indirizzi",
    path: indexOlDRqa8GzFMeta?.path ?? "/area-personale/indirizzi",
    meta: indexOlDRqa8GzFMeta || {},
    alias: indexOlDRqa8GzFMeta?.alias || [],
    redirect: indexOlDRqa8GzFMeta?.redirect,
    component: () => import("/app/pages/area-personale/indirizzi/index.vue").then(m => m.default || m)
  },
  {
    name: nuovoIKw2vQ80gdMeta?.name ?? "area-personale-indirizzi-nuovo",
    path: nuovoIKw2vQ80gdMeta?.path ?? "/area-personale/indirizzi/nuovo",
    meta: nuovoIKw2vQ80gdMeta || {},
    alias: nuovoIKw2vQ80gdMeta?.alias || [],
    redirect: nuovoIKw2vQ80gdMeta?.redirect,
    component: () => import("/app/pages/area-personale/indirizzi/nuovo.vue").then(m => m.default || m)
  },
  {
    name: index4mi8Oh4KGnMeta?.name ?? "area-personale-preferiti",
    path: index4mi8Oh4KGnMeta?.path ?? "/area-personale/preferiti",
    meta: index4mi8Oh4KGnMeta || {},
    alias: index4mi8Oh4KGnMeta?.alias || [],
    redirect: index4mi8Oh4KGnMeta?.redirect,
    component: () => import("/app/pages/area-personale/preferiti/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93tpn8wGPIwuMeta?.name ?? "area-personale-preferiti-page-page",
    path: _91page_93tpn8wGPIwuMeta?.path ?? "/area-personale/preferiti/page/:page()",
    meta: _91page_93tpn8wGPIwuMeta || {},
    alias: _91page_93tpn8wGPIwuMeta?.alias || [],
    redirect: _91page_93tpn8wGPIwuMeta?.redirect,
    component: () => import("/app/pages/area-personale/preferiti/page/[page].vue").then(m => m.default || m)
  },
  {
    name: prodotti_45disponibiliVMGKBK6bLMMeta?.name ?? "area-personale-prodotti-disponibili",
    path: prodotti_45disponibiliVMGKBK6bLMMeta?.path ?? "/area-personale/prodotti-disponibili",
    meta: prodotti_45disponibiliVMGKBK6bLMMeta || {},
    alias: prodotti_45disponibiliVMGKBK6bLMMeta?.alias || [],
    redirect: prodotti_45disponibiliVMGKBK6bLMMeta?.redirect,
    component: () => import("/app/pages/area-personale/prodotti-disponibili.vue").then(m => m.default || m)
  },
  {
    name: wishlist5f1t4McwdNMeta?.name ?? "area-personale-wishlist",
    path: wishlist5f1t4McwdNMeta?.path ?? "/area-personale/wishlist",
    meta: wishlist5f1t4McwdNMeta || {},
    alias: wishlist5f1t4McwdNMeta?.alias || [],
    redirect: wishlist5f1t4McwdNMeta?.redirect,
    component: () => import("/app/pages/area-personale/wishlist.vue").then(m => m.default || m)
  },
  {
    name: indexzbqkdctIA2Meta?.name ?? "b-brand-slug",
    path: indexzbqkdctIA2Meta?.path ?? "/b-:brand()/:slug()",
    meta: indexzbqkdctIA2Meta || {},
    alias: indexzbqkdctIA2Meta?.alias || [],
    redirect: indexzbqkdctIA2Meta?.redirect,
    component: () => import("/app/pages/b-[brand]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93bmo7HXTGS0Meta?.name ?? "b-brand-slug-page-page",
    path: _91page_93bmo7HXTGS0Meta?.path ?? "/b-:brand()/:slug()/page/:page()",
    meta: _91page_93bmo7HXTGS0Meta || {},
    alias: _91page_93bmo7HXTGS0Meta?.alias || [],
    redirect: _91page_93bmo7HXTGS0Meta?.redirect,
    component: () => import("/app/pages/b-[brand]/[slug]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexgj55yT9HTjMeta?.name ?? "b-brand",
    path: indexgj55yT9HTjMeta?.path ?? "/b-:brand()",
    meta: indexgj55yT9HTjMeta || {},
    alias: indexgj55yT9HTjMeta?.alias || [],
    redirect: indexgj55yT9HTjMeta?.redirect,
    component: () => import("/app/pages/b-[brand]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_9323RygVuFlEMeta?.name ?? "b-brand-page-page",
    path: _91page_9323RygVuFlEMeta?.path ?? "/b-:brand()/page/:page()",
    meta: _91page_9323RygVuFlEMeta || {},
    alias: _91page_9323RygVuFlEMeta?.alias || [],
    redirect: _91page_9323RygVuFlEMeta?.redirect,
    component: () => import("/app/pages/b-[brand]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: brands3yQXfZudBaMeta?.name ?? "brands",
    path: brands3yQXfZudBaMeta?.path ?? "/brands",
    meta: brands3yQXfZudBaMeta || {},
    alias: brands3yQXfZudBaMeta?.alias || [],
    redirect: brands3yQXfZudBaMeta?.redirect,
    component: () => import("/app/pages/brands.vue").then(m => m.default || m)
  },
  {
    name: indexae5jzt8yUaMeta?.name ?? "c-category",
    path: indexae5jzt8yUaMeta?.path ?? "/c-:category()",
    meta: indexae5jzt8yUaMeta || {},
    alias: indexae5jzt8yUaMeta?.alias || [],
    redirect: indexae5jzt8yUaMeta?.redirect,
    component: () => import("/app/pages/c-[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_937nLnngNFZ9Meta?.name ?? "c-category-page-page",
    path: _91page_937nLnngNFZ9Meta?.path ?? "/c-:category()/page/:page()",
    meta: _91page_937nLnngNFZ9Meta || {},
    alias: _91page_937nLnngNFZ9Meta?.alias || [],
    redirect: _91page_937nLnngNFZ9Meta?.redirect,
    component: () => import("/app/pages/c-[category]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: carrellon5jYLIrO6hMeta?.name ?? "carrello",
    path: carrellon5jYLIrO6hMeta?.path ?? "/carrello",
    meta: carrellon5jYLIrO6hMeta || {},
    alias: carrellon5jYLIrO6hMeta?.alias || [],
    redirect: carrellon5jYLIrO6hMeta?.redirect,
    component: () => import("/app/pages/carrello.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qGb7sUHr7XMeta?.name ?? "checkout-id",
    path: _91id_93qGb7sUHr7XMeta?.path ?? "/checkout/:id()",
    meta: _91id_93qGb7sUHr7XMeta || {},
    alias: _91id_93qGb7sUHr7XMeta?.alias || [],
    redirect: _91id_93qGb7sUHr7XMeta?.redirect,
    component: () => import("/app/pages/checkout/[id].vue").then(m => m.default || m)
  },
  {
    name: index7ZtduOIoVXMeta?.name ?? "checkout",
    path: index7ZtduOIoVXMeta?.path ?? "/checkout",
    meta: index7ZtduOIoVXMeta || {},
    alias: index7ZtduOIoVXMeta?.alias || [],
    redirect: index7ZtduOIoVXMeta?.redirect,
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: chi_45siamo4wnUAYuAy7Meta?.name ?? "chi-siamo",
    path: chi_45siamo4wnUAYuAy7Meta?.path ?? "/chi-siamo",
    meta: chi_45siamo4wnUAYuAy7Meta || {},
    alias: chi_45siamo4wnUAYuAy7Meta?.alias || [],
    redirect: chi_45siamo4wnUAYuAy7Meta?.redirect,
    component: () => import("/app/pages/chi-siamo.vue").then(m => m.default || m)
  },
  {
    name: contattix7y2v3AkrRMeta?.name ?? "contatti",
    path: contattix7y2v3AkrRMeta?.path ?? "/contatti",
    meta: contattix7y2v3AkrRMeta || {},
    alias: contattix7y2v3AkrRMeta?.alias || [],
    redirect: contattix7y2v3AkrRMeta?.redirect,
    component: () => import("/app/pages/contatti.vue").then(m => m.default || m)
  },
  {
    name: domande_45frequentiZF8EzGCEOLMeta?.name ?? "domande-frequenti",
    path: domande_45frequentiZF8EzGCEOLMeta?.path ?? "/domande-frequenti",
    meta: domande_45frequentiZF8EzGCEOLMeta || {},
    alias: domande_45frequentiZF8EzGCEOLMeta?.alias || [],
    redirect: domande_45frequentiZF8EzGCEOLMeta?.redirect,
    component: () => import("/app/pages/domande-frequenti.vue").then(m => m.default || m)
  },
  {
    name: _91article_935h3hfjk74RMeta?.name ?? "enciclopedia-category-article",
    path: _91article_935h3hfjk74RMeta?.path ?? "/enciclopedia/:category()/:article()",
    meta: _91article_935h3hfjk74RMeta || {},
    alias: _91article_935h3hfjk74RMeta?.alias || [],
    redirect: _91article_935h3hfjk74RMeta?.redirect,
    component: () => import("/app/pages/enciclopedia/[category]/[article].vue").then(m => m.default || m)
  },
  {
    name: indexgBJu1xTQdjMeta?.name ?? "enciclopedia-category",
    path: indexgBJu1xTQdjMeta?.path ?? "/enciclopedia/:category()",
    meta: indexgBJu1xTQdjMeta || {},
    alias: indexgBJu1xTQdjMeta?.alias || [],
    redirect: indexgBJu1xTQdjMeta?.redirect,
    component: () => import("/app/pages/enciclopedia/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93cTc0wwZ6lcMeta?.name ?? "enciclopedia-category-page-page",
    path: _91page_93cTc0wwZ6lcMeta?.path ?? "/enciclopedia/:category()/page/:page()",
    meta: _91page_93cTc0wwZ6lcMeta || {},
    alias: _91page_93cTc0wwZ6lcMeta?.alias || [],
    redirect: _91page_93cTc0wwZ6lcMeta?.redirect,
    component: () => import("/app/pages/enciclopedia/[category]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexQN5gLOZUALMeta?.name ?? "enciclopedia",
    path: indexQN5gLOZUALMeta?.path ?? "/enciclopedia",
    meta: indexQN5gLOZUALMeta || {},
    alias: indexQN5gLOZUALMeta?.alias || [],
    redirect: indexQN5gLOZUALMeta?.redirect,
    component: () => import("/app/pages/enciclopedia/index.vue").then(m => m.default || m)
  },
  {
    name: health_45checkePC2yKKeYyMeta?.name ?? "health-check",
    path: health_45checkePC2yKKeYyMeta?.path ?? "/health-check",
    meta: health_45checkePC2yKKeYyMeta || {},
    alias: health_45checkePC2yKKeYyMeta?.alias || [],
    redirect: health_45checkePC2yKKeYyMeta?.redirect,
    component: () => import("/app/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91refOrder_93t3RyyYtg6rMeta?.name ?? "ordine-traccia-email-refOrder",
    path: _91refOrder_93t3RyyYtg6rMeta?.path ?? "/ordine/traccia/:email()/:refOrder()",
    meta: _91refOrder_93t3RyyYtg6rMeta || {},
    alias: _91refOrder_93t3RyyYtg6rMeta?.alias || [],
    redirect: _91refOrder_93t3RyyYtg6rMeta?.redirect,
    component: () => import("/app/pages/ordine/traccia/[email]/[refOrder].vue").then(m => m.default || m)
  },
  {
    name: trovaz5vtktBJj7Meta?.name ?? "ordine-trova",
    path: trovaz5vtktBJj7Meta?.path ?? "/ordine/trova",
    meta: trovaz5vtktBJj7Meta || {},
    alias: trovaz5vtktBJj7Meta?.alias || [],
    redirect: trovaz5vtktBJj7Meta?.redirect,
    component: () => import("/app/pages/ordine/trova.vue").then(m => m.default || m)
  },
  {
    name: p_45_91product_93_46consteMNeruBsHaMeta?.name ?? "p-product.const",
    path: p_45_91product_93_46consteMNeruBsHaMeta?.path ?? "/p-:product().const",
    meta: p_45_91product_93_46consteMNeruBsHaMeta || {},
    alias: p_45_91product_93_46consteMNeruBsHaMeta?.alias || [],
    redirect: p_45_91product_93_46consteMNeruBsHaMeta?.redirect,
    component: () => import("/app/pages/p-[product].const.ts").then(m => m.default || m)
  },
  {
    name: p_45_91product_93JZZXHGzIDiMeta?.name ?? "p-product",
    path: p_45_91product_93JZZXHGzIDiMeta?.path ?? "/p-:product()",
    meta: p_45_91product_93JZZXHGzIDiMeta || {},
    alias: p_45_91product_93JZZXHGzIDiMeta?.alias || [],
    redirect: p_45_91product_93JZZXHGzIDiMeta?.redirect,
    component: () => import("/app/pages/p-[product].vue").then(m => m.default || m)
  },
  {
    name: index3xmEShFSahMeta?.name ?? "password-dimenticata",
    path: index3xmEShFSahMeta?.path ?? "/password-dimenticata",
    meta: index3xmEShFSahMeta || {},
    alias: index3xmEShFSahMeta?.alias || [],
    redirect: index3xmEShFSahMeta?.redirect,
    component: () => import("/app/pages/password-dimenticata/index.vue").then(m => m.default || m)
  },
  {
    name: modifica_45passwordUajkWhab4AMeta?.name ?? "password-dimenticata-modifica-password",
    path: modifica_45passwordUajkWhab4AMeta?.path ?? "/password-dimenticata/modifica-password",
    meta: modifica_45passwordUajkWhab4AMeta || {},
    alias: modifica_45passwordUajkWhab4AMeta?.alias || [],
    redirect: modifica_45passwordUajkWhab4AMeta?.redirect,
    component: () => import("/app/pages/password-dimenticata/modifica-password.vue").then(m => m.default || m)
  },
  {
    name: profilo_45cancellatoLQf3d6xdH5Meta?.name ?? "profilo-cancellato",
    path: profilo_45cancellatoLQf3d6xdH5Meta?.path ?? "/profilo-cancellato",
    meta: profilo_45cancellatoLQf3d6xdH5Meta || {},
    alias: profilo_45cancellatoLQf3d6xdH5Meta?.alias || [],
    redirect: profilo_45cancellatoLQf3d6xdH5Meta?.redirect,
    component: () => import("/app/pages/profilo-cancellato.vue").then(m => m.default || m)
  },
  {
    name: indexBdCcNvrCQpMeta?.name ?? "promozioni-brand-slug",
    path: indexBdCcNvrCQpMeta?.path ?? "/promozioni/brand/:slug()",
    meta: indexBdCcNvrCQpMeta || {},
    alias: indexBdCcNvrCQpMeta?.alias || [],
    redirect: indexBdCcNvrCQpMeta?.redirect,
    component: () => import("/app/pages/promozioni/brand/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93kxOMRHX44TMeta?.name ?? "promozioni-brand-slug-page-page",
    path: _91page_93kxOMRHX44TMeta?.path ?? "/promozioni/brand/:slug()/page/:page()",
    meta: _91page_93kxOMRHX44TMeta || {},
    alias: _91page_93kxOMRHX44TMeta?.alias || [],
    redirect: _91page_93kxOMRHX44TMeta?.redirect,
    component: () => import("/app/pages/promozioni/brand/[slug]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexV25O3JvHGaMeta?.name ?? "promozioni",
    path: indexV25O3JvHGaMeta?.path ?? "/promozioni",
    meta: indexV25O3JvHGaMeta || {},
    alias: indexV25O3JvHGaMeta?.alias || [],
    redirect: indexV25O3JvHGaMeta?.redirect,
    component: () => import("/app/pages/promozioni/index.vue").then(m => m.default || m)
  },
  {
    name: indexon2XdcPEqSMeta?.name ?? "promozioni-interne-slug",
    path: indexon2XdcPEqSMeta?.path ?? "/promozioni/interne/:slug()",
    meta: indexon2XdcPEqSMeta || {},
    alias: indexon2XdcPEqSMeta?.alias || [],
    redirect: indexon2XdcPEqSMeta?.redirect,
    component: () => import("/app/pages/promozioni/interne/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93LCyVMxJvCIMeta?.name ?? "promozioni-interne-slug-page-page",
    path: _91page_93LCyVMxJvCIMeta?.path ?? "/promozioni/interne/:slug()/page/:page()",
    meta: _91page_93LCyVMxJvCIMeta || {},
    alias: _91page_93LCyVMxJvCIMeta?.alias || [],
    redirect: _91page_93LCyVMxJvCIMeta?.redirect,
    component: () => import("/app/pages/promozioni/interne/[slug]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UxcvBpzPKEMeta?.name ?? "promozioni-special-slug",
    path: _91slug_93UxcvBpzPKEMeta?.path ?? "/promozioni/special/:slug()",
    meta: _91slug_93UxcvBpzPKEMeta || {},
    alias: _91slug_93UxcvBpzPKEMeta?.alias || [],
    redirect: _91slug_93UxcvBpzPKEMeta?.redirect,
    component: () => import("/app/pages/promozioni/special/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHdSe9JRZUEMeta?.name ?? "ricerca",
    path: indexHdSe9JRZUEMeta?.path ?? "/ricerca",
    meta: indexHdSe9JRZUEMeta || {},
    alias: indexHdSe9JRZUEMeta?.alias || [],
    redirect: indexHdSe9JRZUEMeta?.redirect,
    component: () => import("/app/pages/ricerca/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93K8pSmpWFr0Meta?.name ?? "ricerca-page-page",
    path: _91page_93K8pSmpWFr0Meta?.path ?? "/ricerca/page/:page()",
    meta: _91page_93K8pSmpWFr0Meta || {},
    alias: _91page_93K8pSmpWFr0Meta?.alias || [],
    redirect: _91page_93K8pSmpWFr0Meta?.redirect,
    component: () => import("/app/pages/ricerca/page/[page].vue").then(m => m.default || m)
  },
  {
    name: _91file_93RPNxnkYSxJMeta?.name ?? "ricevuta-file",
    path: _91file_93RPNxnkYSxJMeta?.path ?? "/ricevuta/:file()",
    meta: _91file_93RPNxnkYSxJMeta || {},
    alias: _91file_93RPNxnkYSxJMeta?.alias || [],
    redirect: _91file_93RPNxnkYSxJMeta?.redirect,
    component: () => import("/app/pages/ricevuta/[file].vue").then(m => m.default || m)
  },
  {
    name: richiedi_45prodottoUBMumdHv2DMeta?.name ?? "richiedi-prodotto",
    path: richiedi_45prodottoUBMumdHv2DMeta?.path ?? "/richiedi-prodotto",
    meta: richiedi_45prodottoUBMumdHv2DMeta || {},
    alias: richiedi_45prodottoUBMumdHv2DMeta?.alias || [],
    redirect: richiedi_45prodottoUBMumdHv2DMeta?.redirect,
    component: () => import("/app/pages/richiedi-prodotto.vue").then(m => m.default || m)
  },
  {
    name: scontiWdVPkm1ymBMeta?.name ?? "sconti",
    path: scontiWdVPkm1ymBMeta?.path ?? "/sconti",
    meta: scontiWdVPkm1ymBMeta || {},
    alias: scontiWdVPkm1ymBMeta?.alias || [],
    redirect: scontiWdVPkm1ymBMeta?.redirect,
    component: () => import("/app/pages/sconti.vue").then(m => m.default || m)
  },
  {
    name: sitemapbFBPe0edUzMeta?.name ?? "sitemap",
    path: sitemapbFBPe0edUzMeta?.path ?? "/sitemap",
    meta: sitemapbFBPe0edUzMeta || {},
    alias: sitemapbFBPe0edUzMeta?.alias || [],
    redirect: sitemapbFBPe0edUzMeta?.redirect,
    component: () => import("/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: social_45loginFeA9uOUMWOMeta?.name ?? "social-login",
    path: social_45loginFeA9uOUMWOMeta?.path ?? "/social-login",
    meta: social_45loginFeA9uOUMWOMeta || {},
    alias: social_45loginFeA9uOUMWOMeta?.alias || [],
    redirect: social_45loginFeA9uOUMWOMeta?.redirect,
    component: () => import("/app/pages/social-login.vue").then(m => m.default || m)
  },
  {
    name: indexha0kWLU1LPMeta?.name ?? "top-magazine-category-slug",
    path: indexha0kWLU1LPMeta?.path ?? "/top-magazine/:category()/:slug()",
    meta: indexha0kWLU1LPMeta || {},
    alias: indexha0kWLU1LPMeta?.alias || [],
    redirect: indexha0kWLU1LPMeta?.redirect,
    component: () => import("/app/pages/top-magazine/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKNeVqgUZ73Meta?.name ?? "top-magazine-category",
    path: indexKNeVqgUZ73Meta?.path ?? "/top-magazine/:category()",
    meta: indexKNeVqgUZ73Meta || {},
    alias: indexKNeVqgUZ73Meta?.alias || [],
    redirect: indexKNeVqgUZ73Meta?.redirect,
    component: () => import("/app/pages/top-magazine/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93HJVRPFnC4TMeta?.name ?? "top-magazine-category-page-page",
    path: _91page_93HJVRPFnC4TMeta?.path ?? "/top-magazine/:category()/page/:page()",
    meta: _91page_93HJVRPFnC4TMeta || {},
    alias: _91page_93HJVRPFnC4TMeta?.alias || [],
    redirect: _91page_93HJVRPFnC4TMeta?.redirect,
    component: () => import("/app/pages/top-magazine/[category]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexN3DvlIEeETMeta?.name ?? "top-magazine",
    path: indexN3DvlIEeETMeta?.path ?? "/top-magazine",
    meta: indexN3DvlIEeETMeta || {},
    alias: indexN3DvlIEeETMeta?.alias || [],
    redirect: indexN3DvlIEeETMeta?.redirect,
    component: () => import("/app/pages/top-magazine/index.vue").then(m => m.default || m)
  },
  {
    name: indexr1eLSmTyQpMeta?.name ?? "top-magazine-tutti-i-post",
    path: indexr1eLSmTyQpMeta?.path ?? "/top-magazine/tutti-i-post",
    meta: indexr1eLSmTyQpMeta || {},
    alias: indexr1eLSmTyQpMeta?.alias || [],
    redirect: indexr1eLSmTyQpMeta?.redirect,
    component: () => import("/app/pages/top-magazine/tutti-i-post/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93LgSLLPnjLwMeta?.name ?? "top-magazine-tutti-i-post-page-page",
    path: _91page_93LgSLLPnjLwMeta?.path ?? "/top-magazine/tutti-i-post/page/:page()",
    meta: _91page_93LgSLLPnjLwMeta || {},
    alias: _91page_93LgSLLPnjLwMeta?.alias || [],
    redirect: _91page_93LgSLLPnjLwMeta?.redirect,
    component: () => import("/app/pages/top-magazine/tutti-i-post/page/[page].vue").then(m => m.default || m)
  },
  {
    name: component_45stubTOq3NWgWLHMeta?.name ?? undefined,
    path: component_45stubTOq3NWgWLHMeta?.path ?? "/sitemap.xml",
    meta: component_45stubTOq3NWgWLHMeta || {},
    alias: component_45stubTOq3NWgWLHMeta?.alias || [],
    redirect: component_45stubTOq3NWgWLHMeta?.redirect,
    component: () => import("/app/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]