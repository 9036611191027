<script setup lang="ts">
import type { GA4Entities } from "nuxt-ga4"
import { SearchProps } from "./Search.props"
import { formatMoney } from "~/utils/formatters"
import { truncateString } from "~/utils/truncateString"
import { PRODUCT_CLICKED } from "~/config/algolia-insights"
import {
  algoliaEventClickProductAfterSearch,
  algoliaEventClickProduct
} from "~/utils/algolia"
import { algoliaSearch } from "~/config/algolia"
import { fallbackImage, addToCartPosition } from "~/utils/constants"
import { ProductCardButtonsProps } from "~/components/Utils/ProductCardButtons.props"
import { isGreaterThan } from "~/utils/productPrice"
import { productUtils } from "~/utils/product"
import { SaveForLaterProductForAction } from "~/composables/useSavedForLater"
import { getSkuItemMetadata } from "~/utils/savedForLater/savedForLater"
import { cleanObject } from "~/utils/analytics/analytics"

const props = withDefaults(defineProps<SearchProps>(), {
  inStock: true,
  sellabilityStatus: "sellable",
  longVersion: false,
  fullWidth: false,
  isSearchResults: false,
  noButton: false,
  quantity: 0,
  showPricePerUnit: true
})

const { addToCart } = useCart()
const skuState = useState("product-unavailable-state", () => "")

const disableLink = ref(false)

const route = useRoute()

const emit =
  defineEmits<{
    (e: "notification"): void
    (e: "onCloseModal"): void
  }>()

const truncateTitle = computed(() => truncateString(props.title, 50))

const handleNotifyMe = () => {
  skuState.value = props?.productCode ?? ""
}

/** Define single GA4 item */
const GAItemCart = computed((): GA4Entities["gaItem"] => {
  return {
    item_id: props?.productCode ?? "",
    item_name: props.title ?? "",
    index: 0,
    price: props.currentPrice,
    item_list_name: props.itemList ?? "",
    item_brand: props.brand?.name ?? "",
    item_category: props.categories?.main ?? "",
    item_category2: props.categories?.second ?? "",
    item_category3: props.categories?.third ?? "",
    item_category4: props.categories?.fourth ?? "",
    item_category5: props.categories?.fifth ?? "",
    currency: "EUR",
    discount:
      props.oldPrice && props.currentPrice
        ? props.oldPrice - props.currentPrice
        : "",
    available: props.sellabilityStatus != "not_sellable" ? "si" : "no",
    quantity: 1,
    currency: "EUR"
  }
})

const handleAddToCart = (quantity: number) => {
  emit("notification")

  const { sendEventAddToCart } = useGA4SiteEvents()
  isAddingToCart.value = true

  useTimeoutFn(() => {
    isAddingToCart.value = false
  }, 2000)

  addToCart(
    {
      sku_code: props.productCode,
      metadata: {
        product_image: props?.productImage!,
        slug: props.path,
        price: props.currentPrice,
        oldPrice: props.oldPrice,
        inStock: props.inStock,
        expirationDate: props?.expirationDate ?? "",
        unitType: props?.unitType ?? "",
        isDeductable: props?.isDeductable,
        ga4Item: GAItemCart.value ? cleanObject(GAItemCart.value) : {},
        afterSearch: {
          queryID: props.queryID
        }
      }
    },
    quantity
  )

  if (Object.keys(GAItemCart?.value ?? {}).length) {
    sendEventAddToCart(GAItemCart?.value ?? {}, {
      currentPrice: props.currentPrice?.toString() ?? "",
      position: addToCartPosition.listing
    })
  }
}

const isAddingToCart = ref(false)

const clickProductEvent = () => {
  const algolia = algoliaSearch()
  localStorage.setItem(
    "algolia-last-clicked-product",
    `{"queryID": "${props?.queryID ?? ""}","path": "${props?.path ?? ""}"}`
  )
  if (props.queryID) {
    algoliaEventClickProductAfterSearch(
      algolia.indices.products,
      `${PRODUCT_CLICKED}`,
      [`product_${props.productCode}`],
      props.queryID,
      [props.position ?? 1]
    )
  } else {
    algoliaEventClickProduct(algolia.indices.products, `${PRODUCT_CLICKED}`, [
      `product_${props.productCode}`
    ])
  }

  if (props.path == route.path) emit("onCloseModal")
}

const product = computed((): SaveForLaterProductForAction => {
  return {
    productId: props?.productCode ?? "",
    skuId: "",
    metadata: getSkuItemMetadata(
      props?.productImage,
      props?.path ?? "",
      props.currentPrice,
      props.oldPrice,
      props.inStock ? 1 : 0,
      props.unitType,
      props.expirationDate,
      props.isDeductable,
      GAItemCart.value ? cleanObject(GAItemCart.value) : {}
    )
  }
})

const productCardButton = computed((): ProductCardButtonsProps => {
  return {
    inStock: props.inStock,
    sellabilityStatus: props.sellabilityStatus,
    userLogged: props.userLogged,
    queryID: props.queryID,
    position: props.position,
    productCode: props.productCode,
    quantity: props.quantity,
    addToCartButtonText: props.addToCartButtonText,
    theme: props.theme,
    path: props.path,
    product: product.value
  }
})

const discount = discountPercentage(props.currentPrice, props.oldPrice)
</script>

<template>
  <div
    class="
      product_tile-overall-search
      relative
      block
      w-full
      transform-gpu
      rounded-lg
      bg-white
      px-4
      py-2
      drop-shadow-01
      transition
      hover:drop-shadow-02
    "
  >
    <GAItem :id="`search--${props.productCode ?? ''}`" :item="[props]">
      <div class="flex">
        <div class="h-[102px] w-[70px] shrink-0 md:h-[122px] md:w-[86px]">
          <GASelectItem method="ricerca" :eventID="productCode">
            <AppLink
              :isDisabled="disableLink"
              :to="path"
              @click="clickProductEvent"
            >
              <UtilsWrapperImage
                v-if="productImage"
                v-bind="productImage"
                :key="productCode"
                class="wrapper-image-fit-contain aspect-square"
                sizes="70px md:86px"
              />
              <UtilsWrapperImage
                v-else
                v-bind="fallbackImage"
                class="wrapper-image-fit-contain"
                sizes="70px md:86px"
              />
            </AppLink>
          </GASelectItem>
        </div>
        <!-- CONTENT-CONTAINER -->
        <div
          class="content-contaner ml-2"
          :class="{
            'flex flex-col justify-between': fullWidth,
            'w-full': longVersion || fullWidth,
            'flex w-full flex-col justify-between': noButton,
            'flex w-full flex-col': isSearchResults
          }"
        >
          <!-- TITLE -->
          <p
            class="title mouse-bold mb-2"
            :class="{
              'md:mb-5': longVersion,
              'line-clamp-2': fullWidth || isSearchResults
            }"
          >
            <GASelectItem method="ricerca" :eventID="productCode">
              <AppLink
                :isDisabled="disableLink"
                :to="path"
                @click="clickProductEvent"
              >
                {{ noButton ? truncateTitle : title }}
              </AppLink>
            </GASelectItem>
          </p>

          <!-- QUANTITY INFO -->
          <p v-if="noButton && quantity > 1" class="mouse mb-2 text-slate-main">
            {{ $t("productTile.overall.unity") }}: {{ quantity }}
          </p>
          <!-- PRICE + BUTTON CONTAINER -->
          <div
            :class="{
              'md:flex md:items-center md:justify-between': longVersion,
              'flex flex-auto flex-col justify-between': isSearchResults
            }"
          >
            <!-- PRICE INFO -->
            <div
              class="flex items-start"
              :class="{
                'mb-2': !longVersion,
                'mb-0 justify-between': noButton,

                'mb-2 md:mb-0': longVersion
              }"
              v-if="inStock || productUtils.isSellableStatus(sellabilityStatus)"
            >
              <div class="flex flex-wrap items-center gap-2 md:mb-0">
                <!-- Badges -->
                <div class="z-10 flex flex-wrap gap-2">
                  <BadgeProduct v-for="promo in promotions" v-bind="promo" />
                </div>
                <div class="flex items-center gap-2 md:mb-0 md:flex-wrap">
                  <span
                    v-if="currentPrice"
                    class="
                      discount-price
                      pig-bold
                      md:dolphin-bold
                      text-black-800
                    "
                    :class="{ 'md:dolphin-bold': longVersion }"
                  >
                    {{ formatMoney(currentPrice) }}
                  </span>

                  <span
                    v-if="isGreaterThan(oldPrice, currentPrice)"
                    class="full-price snail md:mouse text-black-80 line-through"
                    :class="{ 'md:mouse': longVersion }"
                  >
                    {{ formatMoney(oldPrice) }}
                  </span>

                  <template
                    v-if="
                      showPricePerUnit &&
                      productUtils.isDisplayablePricePerUnit(
                        pricePerUnit,
                        unitType
                      )
                    "
                  >
                    <span
                      v-if="!noButton || fullWidth"
                      class="price-per-unit snail hidden text-black-80 md:block"
                    >
                      {{
                        formattedPricePerUnit(
                          formatMoney(pricePerUnit),
                          unitType
                        )
                      }}
                    </span>
                  </template>
                </div>
              </div>
            </div>
            <!-- NOT SELLABLE PRODUCT -->
            <div
              class="flex items-start"
              :class="{
                'mb-2': !longVersion,
                'mb-0 justify-between': noButton,
                'mb-2 md:mb-0': longVersion
              }"
              v-if="
                !inStock && !productUtils.isSellableStatus(sellabilityStatus)
              "
            >
              <div class="flex flex-wrap items-center gap-2 md:mb-0">
                <!-- Badges -->
                <div class="z-10 flex flex-wrap gap-2">
                  <span
                    class="
                      badge-expiry
                      snail-bold
                      md:mouse-bold
                      flex
                      items-center
                      whitespace-nowrap
                      rounded
                      bg-orange-10
                      p-1
                      text-black-main
                      md:px-2
                      lg:py-[2px]
                    "
                  >
                    <UtilsIcon name="Warning.svg" class="mr-1 h-6 w-6" />
                    {{ $t("productTile.overall.notSellable") }}
                  </span>
                </div>
                <div class="flex items-center gap-2 md:mb-0 md:flex-wrap">
                  <span
                    class="full-price snail md:mouse text-black-80"
                    :class="{ 'md:mouse': longVersion }"
                  >
                    {{ $t("productTile.overall.notSellableSuggestions") }}
                  </span>
                </div>
              </div>
            </div>
            <!-- BUTTON CONTAINER -->
            <div
              class="button-container"
              :class="{ 'md:mr-2': isSearchResults }"
              v-if="!noButton"
            >
              <div
                v-if="
                  !inStock && productUtils.isSellableStatus(sellabilityStatus)
                "
                class="flex justify-center py-3"
              >
                <span class="beaver text-negative-main">
                  {{
                    availableIn
                      ? `${$t(
                          "productTile.overall.availableIn"
                        )} ${availableIn} ${$t(
                          availableIn > 1
                            ? "productTile.overall.days"
                            : "productTile.overall.day"
                        )}`
                      : $t("productTile.overall.notAvailable")
                  }}
                </span>
              </div>
              <div v-if="alreadyInCart && inStock" class="py-1">
                <span class="beaver">
                  {{ $t("productTile.overall.alreadyInCart") }}
                </span>
              </div>

              <UtilsProductCardButtons
                v-else-if="currentPrice && currentPrice > 0"
                v-bind="productCardButton"
                @on-add-to-cart="handleAddToCart($event)"
                @on-notify-me="handleNotifyMe"
                class="[&_span]:whitespace-normal [&_span]:text-center"
                :class="{ hidden: currentPrice === 0 }"
              />
            </div>
          </div>
        </div>
      </div>
    </GAItem>
  </div>
</template>
<style scoped lang="scss">

:deep(.btn__container) {
  @apply md:mouse md:py-[6.5px];
}

</style>
