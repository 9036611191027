import { defineNuxtPlugin } from "#app"
import consola from "consola"
import { sentryCaptureException } from "../utils/sentry/sentryCaptureException"

export default defineNuxtPlugin(({ vueApp, hook }) => {
  vueApp.config.errorHandler = (error, instance, info) => {
    if (process.server) {
      consola.error(
        new Date().toISOString(),
        "VUE ERROR",
        error ? stringifyCircularJSON(error) : undefined,
        info
      )
      consola.error(
        new Date().toISOString(),
        "VUE ERROR - INSTANCE",
        instance ? stringifyCircularJSON(instance) : undefined
      )
    }
    sentryCaptureException(error, "pages", "fetch", "usePage")
  }
  hook("vue:error", (error, instance, info) => {
    if (process.server) {
      consola.error(
        new Date().toISOString(),
        "VUE ERROR",
        error ? stringifyCircularJSON(error) : undefined,
        info
      )
      consola.error(
        new Date().toISOString(),
        "VUE ERROR - INSTANCE",
        instance ? stringifyCircularJSON(instance) : undefined
      )
    }
  })
  hook("app:error", (error) => {
    if (process.server) {
      consola.error(
        new Date().toISOString(),
        "Nuxt app error",
        error.message,
        error ? stringifyCircularJSON(error) : undefined
      )
    }
  })
  hook("app:rendered", (renderContext) => {
    if (process.server) {
      consola.info(
        new Date().toISOString(),
        "Nuxt app rendered",
        renderContext.ssrContext?.url
        //renderContext ? stringifyCircularJSON(renderContext) : undefined
      )
    }
  })
})

const stringifyCircularJSON = (obj: any) => {
  const seen = new WeakSet()
  return JSON.stringify(obj, (k, v) => {
    if (v !== null && typeof v === "object") {
      if (seen.has(v)) return
      seen.add(v)
    }
    return v
  })
}
